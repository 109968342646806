import { SettingsService } from './../../service/settings.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TodoViewModel } from 'src/app/interfaces/toDoView.interface';
import { TodosService } from 'src/app/service/todos.service';
import { TodoAddComponent } from '../todo-add/todo-add.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '.app-todo-list .col',
  templateUrl: './todo-list.component.html',
  styleUrls: ['./todo-list.component.scss'],
})
export class TodoListComponent implements OnInit, OnDestroy {
  constructor(
    private todoService: TodosService,
    private modalService: NgbModal,
    public settingsService: SettingsService
  ) {}

  todos: TodoViewModel[] = [];
  overdueTodos: TodoViewModel[] = [];
  doneTodos: TodoViewModel[] = [];
  destroy$ = new Subject();
  userId = this.settingsService.user.uid;

  ngOnDestroy(): void {
    this.destroy$.next();
  }
  ngOnInit(): void {
    this.loadTodos(this.userId);
  }

  clickAddTodo() {
    const modal = this.modalService.open(TodoAddComponent);
    modal.result.then(
      this.handleModalTodoFormClose.bind(this),
      this.handleModalTodoFormClose.bind(this)
    );
  }

  handleModalTodoFormClose(response) {
    // is response an object?
    if (response === Object(response)) {
      if (response.createMode) {
        response.todo.id = response.id;
        this.todos.unshift(response.todo);
      } else {
        const index = this.todos.findIndex((value) => value.id === response.id);
        this.todos[index] = response.todo;
      }
    }
  }

  loadTodos(userId: string) {
    this.todoService
      .getTodos(userId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.doneTodos = [];
        this.todos = [];

        response.docs.forEach((value) => {
          const data = value.data();
          const id = value.id;
          let toDoDate: Date;
          const dateNow = new Date();

          if (data.toDoDate) {
            toDoDate = data.toDoDate.toDate();
          }
          const todo: TodoViewModel = {
            id,
            title: data.title,
            description: data.description,
            done: data.done,
            lastModifiedDate: data.lastModifiedDate.toDate(),
            toDoDate,
            sortIndex: data.sortIndex,
          };

          if (todo.done) {
            this.doneTodos.push(todo);
          } else if (toDoDate && toDoDate < dateNow) {
            this.overdueTodos.push(todo);
          } else {
            this.todos.push(todo);
          }
        });
        this.sort(this.todos);
      });
  }

  checkedDone(index: number, done: boolean) {
    const newDoneValue = !done;
    const obj = { done: newDoneValue };
    if (!done) {
      this.todos[index].done = newDoneValue;
      const id = this.todos[index].id;
      this.todoService.editTodoPartial(id, obj);
      this.doneTodos.push(this.todos[index]);
      this.todos.splice(index, 1);
    } else {
      this.doneTodos[index].done = newDoneValue;
      const id = this.doneTodos[index].id;
      this.todoService.editTodoPartial(id, obj);
      this.todos.push(this.doneTodos[index]);
      this.doneTodos.splice(index, 1);
    }
    this.saveSortIndex(this.todos);
  }

  handleEditClick(todo: TodoViewModel) {
    const modal = this.modalService.open(TodoAddComponent);
    modal.result.then(
      this.handleModalTodoFormClose.bind(this),
      this.handleModalTodoFormClose.bind(this)
    );
    modal.componentInstance.createMode = false;
    modal.componentInstance.todo = todo;
  }

  handleDeleteClick(todoId: string, index: number) {
    this.todoService
      .deleteTodo(todoId)
      .then(() => {
        this.todos.splice(index, 1);
      })
      .catch((err) => console.error(err));
  }

  drop(event: CdkDragDrop<TodoViewModel[]>) {
    moveItemInArray(this.todos, event.previousIndex, event.currentIndex);
    this.settingsService.settings.todoSort = 'custom';
    this.settingsService.editSettingsPartial(this.settingsService.settings.id, {
      todoSort: 'custom',
    });
    this.saveSortIndex(this.todos);
  }

  sort(todos: TodoViewModel[]) {
    if (this.settingsService.settings.todoSort === 'custom') {
      todos.sort((a, b) => a.sortIndex - b.sortIndex);
    }
    if (this.settingsService.settings.todoSort === 'title') {
      todos.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    }
    if (this.settingsService.settings.todoSort === 'date') {
      todos.sort((a, b) => {
        let x: Date;
        let y: Date;
        if (a.toDoDate) {
          x = a.toDoDate;
        } else {
          x = a.lastModifiedDate;
        }

        if (b.toDoDate) {
          y = b.toDoDate;
        } else {
          y = b.lastModifiedDate;
        }

        if (x < y) {
          return -1;
        }
        if (x > y) {
          return 1;
        }
        return 0;
      });
    }
  }

  sortTodos(type: string) {
    this.settingsService.settings.todoSort = type;
    this.settingsService.editSettingsPartial(this.settingsService.settings.id, {
      todoSort: type,
    });
    this.sort(this.todos);
    this.sort(this.overdueTodos);
  }
  saveSortIndex(todos: TodoViewModel[]) {
    todos.forEach((todo, index) => {
      this.todoService.editTodoPartial(todo.id, { sortIndex: index + 1 });
    });
  }
}
