import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { Settings } from '../interfaces/settings.interface';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  private collectionName = 'settings';
  public settings: Settings;
  public user: User;
  public subscribtion$: Subscription;

  constructor(private db: AngularFirestore, private afAuth: AngularFireAuth) {
    this.settings = {
      id: '',
      userId: ''
    };
  }
  startSettings(user: User) {
    if (user) {
      this.user = user;
    }

    this.subscribtion$ = this.getSettings(this.user.uid)
      .subscribe((response) => {
        if (!response.docs.length) {
          this.settings.userId = this.user.uid;
          this.settings.todoSort = 'custom';
          this.saveSettings(this.settings);
        } else {
          response.docs.forEach((value) => {
            const data = value.data();
            const id = value.id;
            this.settings.id = id;
            this.settings.userId = data.userId;
            this.settings.todoSort = data.todoSort;
          });
        }
      });
  }
  getSettings(userId: string): Observable<firebase.firestore.QuerySnapshot> {
    return this.db
      .collection<Settings>(this.collectionName, (ref) =>
        ref.where('userId', '==', userId)
      )
      .get();
  }
  saveSettings(settings: Settings): Promise<DocumentReference> {
    return this.db.collection(this.collectionName).add(settings);
  }
  editSettings(settings: Settings): Promise<void> {
    return this.db
      .collection(this.collectionName)
      .doc(settings.id)
      .update(settings);
  }
  editSettingsPartial(id: string, obj: any): Promise<void> {
    return this.db.collection(this.collectionName).doc(id).update(obj);
  }
}
