import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { Todo } from '../interfaces/toDos.interface';
import { TodoViewModel } from '../interfaces/toDoView.interface';

@Injectable({
  providedIn: 'root',
})
export class TodosService {
  constructor(private db: AngularFirestore) {}

  private collectionName = 'todos';

  getTodos(userId: string): Observable<firebase.firestore.QuerySnapshot> {
    return this.db
      .collection<Todo>(this.collectionName, (ref) =>
        ref.where('userId', '==', userId)
      )
      .get();
  }

  saveTodo(todo: Todo): Promise<DocumentReference> {
    return this.db.collection(this.collectionName).add(todo);
  }

  editTodo(todo: TodoViewModel): Promise<void> {
    return this.db.collection(this.collectionName).doc(todo.id).update(todo);
  }

  editTodoPartial(id: string, obj: any): Promise<void> {
    return this.db.collection(this.collectionName).doc(id).update(obj);
  }

  deleteTodo(idTodo: string): Promise<void> {
    return this.db.collection(this.collectionName).doc(idTodo).delete();
  }
}
