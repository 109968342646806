import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { DocumentReference } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'firebase';
import { Todo } from 'src/app/interfaces/toDos.interface';
import { TodoViewModel } from 'src/app/interfaces/toDoView.interface';
import { TodosService } from 'src/app/service/todos.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '.app-todo-add .col',
  templateUrl: './todo-add.component.html',
  styleUrls: ['./todo-add.component.scss'],
})
export class TodoAddComponent implements OnInit {

  constructor(
      private formBuilder: FormBuilder,
      public activeModal: NgbActiveModal,
      private todoService: TodosService,
      private afAuth: AngularFireAuth
    ) { }

  todoForm: FormGroup;
  createMode = true;
  todo: TodoViewModel;
  user: User;

  ngOnInit() {
    this.todoForm = this.formBuilder.group({
      title: ['', Validators.required],
      description: null,
      done: false,
      toDoDate: null
    });

    if (!this.createMode) { this.loadTodo(this.todo); }

    this.afAuth.user.subscribe(user => {
      if (user) {
        this.user = user;
      }
    });
  }
  loadTodo(todo) {
    this.todoForm.patchValue(todo);
  }
  saveTodo() {
    if (this.todoForm.invalid) {
      return;
    }
    if (this.createMode) {
      const todo: Todo = this.todoForm.value;
      todo.lastModifiedDate = new Date();
      todo.createdDate = new Date();
      todo.userId = this.user.uid;
      todo.sortIndex = 0;
      this.todoService.saveTodo(todo)
       .then(response => this.handleSuccessfulSaveTodo(response, todo)) 
       .catch (err => console.error(err));
    } else {
      const todo: TodoViewModel = this.todoForm.value;
      todo.id = this.todo.id;
      todo.lastModifiedDate = new Date();
      this.todoService.editTodo(todo)
        .then(() => this.handleSuccessfulEditTodo(todo))
        .catch(err => console.error(err));
    }
  }
  handleSuccessfulSaveTodo(response: DocumentReference, todo: Todo) {
    this.activeModal.dismiss({ todo, id: response.id, createMode: true });
  }

  handleSuccessfulEditTodo(todo: TodoViewModel) {
    this.activeModal.dismiss({ todo, id: todo.id, createMode: false });
  }
}
