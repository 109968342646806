import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsService } from '../service/settings.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: '.app-login .col',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private fb: FormBuilder,
    private ngZone: NgZone,
    private settingsService: SettingsService
  ) {}

  errorMessage = '';
  destroy$ = new Subject();

  loginForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required],
  });

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  ngOnInit() {
    this.afAuth.user.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user) {
        this.settingsService.startSettings(user);

        this.ngZone.run(() => {
          this.router.navigate(['/todoList']);
        });
      }
    });
  }
  createUser() {
    this.afAuth
      .createUserWithEmailAndPassword(
        this.loginForm.value.email,
        this.loginForm.value.password
      )
      .then(() => {
        this.router.navigate(['/todoList']);
      })
      .catch((response) => {
        this.errorMessage = response.message;
      });
  }
  signIn() {
    this.afAuth
      .signInWithEmailAndPassword(
        this.loginForm.value.email,
        this.loginForm.value.password
      )
      .then(() => {
        this.router.navigate(['/todoList']);
      })
      .catch((response) => {
        this.errorMessage = response.message;
      });
  }
}
