import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { User } from 'firebase';
import { SettingsService } from './service/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'jarvis';

  constructor(
    private afAuth: AngularFireAuth,
    private router: Router,
    private ngZone: NgZone,
    public settingsService: SettingsService
  ) {}

  ngOnDestroy(): void {
    this.settingsService.subscribtion$.unsubscribe();
  }

  ngOnInit(): void {
    if (!this.settingsService.user) {
      this.ngZone.run(() => {
        this.router.navigate(['']);
      });
    }
  }
  logout() {
    this.afAuth.signOut();
  }
}
